/* eslint-disable camelcase, no-undef,no-console */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem, DropdownButton } from 'react-bootstrap';
import { QuestionContainer, QuizContainer, OptionsBlock, Flex, Layout } from './styles';
import { Float, Title, AdventureTitle, TitleContainer, EditButton } from '../Article/styles';
import { StyledCheckBox } from '../RegistrationWellnessInterests/styles';
import { ArticleButtonsContainer, BackButton } from '../Journeys/styles';
import { NoComments, DownAngle } from '../SocialFeeds/styles';
import Waiting from '../Waiting';
const DeletePostPopUp = React.lazy(() => import('../DeletePostPopup'));
import { DeleteQuizQuestion, FetchQuizzes, getAdventureArticle } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
class ShowAllQuiz extends Component {
  constructor(props){
    super(props);
    this.state = {
      quizQuestions: null,
      optionSelection: null,
      buttonDisable: true,
      editAndDelete: '',
      deleteIndex: null,
      adventureArticleDataStore:[]
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    const { adventureArticle, history,match, fetchQuizzes, fetchAdventureArticle } = this.props;
    fetchAdventureArticle(match.params.id, match.params.categoryName);
    const stringArray = history.location.pathname.split('/');
    const adventureId = stringArray[stringArray.length - 2];
    this.setState({ adventureArticleDataStore: adventureArticle });
    if(!adventureArticle) {
      history.push(`/education/adventure/${stringArray[3]}/article/${adventureId}`);
    }
    else {
      fetchQuizzes(adventureArticle.id, 1);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { quizQuestions } = nextProps;
    if(quizQuestions) {
      let arr = [];
      for(let i=0; i<quizQuestions.length; i++) {
        arr.push('');
      }
      this.setState({ quizQuestions, optionSelection: arr });
    }
  }

  changeEditAndDelete = (value, index) => {
    this.setState({
      editAndDelete: value,
      deleteIndex: index
    })
  }

  optionChoice = (index, value, t) => {
    switch(index) {
    case 0: 
      return `a. ${t(value)}`;
    case 1:
      return `b. ${t(value)}`;
    case 2:
      return `c. ${t(value)}`;
    case 3:
      return `d. ${t(value)}`;
    }
  };
  editQuizQuestions = (list) => {
    const { history } = this.props;
    const stringArray = history.location.pathname.split('/');
    const adventureId = stringArray[stringArray.length - 2];
    history.push(`/education/adventure/${stringArray[3]}/article/${adventureId}/edit-quiz/${list.id}`)
  };
  quizDashBoard = () => (
    this.state.quizQuestions.map((list, questionIndex) => (
      <QuestionContainer key={questionIndex}>
        <Flex role={this.props.role == 'ADMIN' ? 1 : 0} noAlign={1}>
          <div className="question"> {(questionIndex+1)+'. '+this.props.t(list.quiz)}</div>
          {
            this.props.createOrUpdateQuiz &&
            <div className="edit">
              <DownAngle>
                <DropdownButton id="editDelete" title={<i className="fas fa-angle-down" />}>
                  {this.props.updateQuiz && <DropdownItem onClick={()=>{this.editQuizQuestions(list)}}>
                    <i className="fas fa-pencil-alt" />{this.props.t("Edit")}
                  </DropdownItem>}
                  {this.props.deleteQuiz && <DropdownItem onClick={() => this.changeEditAndDelete('delete', list.id)}>
                    <i className="fa fa-trash" aria-hidden="true" />{this.props.t("Delete")}
                  </DropdownItem>}
                </DropdownButton>
              </DownAngle>
            </div>
          }
        </Flex>
        { list.choices.map((option, optionIndex) => (
          <OptionsBlock key={optionIndex}>
            <StyledCheckBox >
              <span>{this.optionChoice(optionIndex, option.choice, this.props.t)}</span>
            </StyledCheckBox>
          </OptionsBlock>
        ))}
      </QuestionContainer>
    ))
  );

  goBack = () => {
    const {  history } = this.props;
    const {  adventureArticleDataStore } = this.state;
    history.push(`/education/adventure/${adventureArticleDataStore.category_name}/article/${adventureArticleDataStore.id}`);
  }

  addQuiz = () => {
    const { history } = this.props;
    const stringArray = history.location.pathname.split('/');
    const adventureId = stringArray[stringArray.length - 2];
    history.push(`/education/adventure/${stringArray[3]}/article/${adventureId}/add-quiz`);
  }

  render() {
    const { createQuiz, t } = this.props;
    const { quizQuestions, editAndDelete, deleteIndex, adventureArticleDataStore } = this.state;
    if(!adventureArticleDataStore || !quizQuestions) {
      return <Waiting />
    }

    return (
      <Layout>
        <TitleContainer>
          <Title mainTitle>{t(adventureArticleDataStore.category_name)}</Title>
          <AdventureTitle>{t(adventureArticleDataStore.title)}</AdventureTitle>
        </TitleContainer>
        <ArticleButtonsContainer right>
          <BackButton onClick={() => this.goBack()}>
            <img src="/public/images/educationAdventureIcons/back-arrow.png" alt="back-arrow" />
            {t("Back")}
          </BackButton>
        </ArticleButtonsContainer> 
        {createQuiz && 
        <Float>
          <EditButton onClick={() => this.addQuiz()} marginTop="10px" style={{width: "fit-content"}}>{t("Add Question")}</EditButton>
        </Float>
        }
        <QuizContainer>
          <div>
            { quizQuestions.length > 0 && 
              <div>
                <div className="title">{t("Quiz Questions")}</div>
                { this.quizDashBoard() }
              </div>
            }
          </div>
        </QuizContainer>

        { quizQuestions.length <= 0 &&
          <Flex marginTop="35px" textAlign={1}>
            <NoComments> {t("No Quizzes")}</NoComments>
          </Flex>
        }
        <Suspense fallback={<Waiting/>}>
          {
            editAndDelete == 'delete' &&
            <DeletePostPopUp 
              showModal={editAndDelete == 'delete'}
              onClose={this.changeEditAndDelete}
              questionPopup = {1}
              title={t("DELETE QUESTION")}
              subContent={t("This action cannot be undone.")}
              deleteId = {deleteIndex}
              deleteQuizQuestion={this.props.deleteQuizQuestion}
              articleId={adventureArticleDataStore.id}
              updationList=''/>
          }
        </Suspense>
      </Layout>
    );
  }
}

ShowAllQuiz.propTypes = {
  adventureArticle: PropTypes.object,
  history: PropTypes.object,
  role: PropTypes.string.isRequired,
  quizQuestions: PropTypes.array,
  fetchQuizzes: PropTypes.func.isRequired,
  deleteQuizQuestion: PropTypes.func.isRequired,
  deleteQuiz: PropTypes.bool,
  createQuiz: PropTypes.bool,
  updateQuiz: PropTypes.bool,
  createOrUpdateQuiz: PropTypes.bool,
  t: PropTypes.func,
  match: PropTypes.object,
  fetchAdventureArticle: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  adventureArticle: state.education.journeyDetails,  
  quizQuestions: state.education.quizQuestions
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuizzes:(id, value) => dispatch(FetchQuizzes(id, value)),
  deleteQuizQuestion: (id, articleId) => dispatch(DeleteQuizQuestion(id, articleId)),
  fetchAdventureArticle: (id, category) => dispatch(getAdventureArticle(id, category)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShowAllQuiz)));